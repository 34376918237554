<template>
  <div id="app"></div>
</template>

<script>
import axios from 'axios'; // 引入axios进行HTTP请求
import {getCurrentBrowserFingerPrint} from "@rajesh896/broprint.js";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      plugins: [],
      fingerprint: '', // 用于存储浏览器指纹的属性
      apiUrl: 'https://cj.httoutiao.com/submit' // 你的接口地址
    };
  },
  mounted() {
   
    this.submitData();
  },
  methods: {
    submitData() {
      getCurrentBrowserFingerPrint().then(fingerprint => {
       
        this.fingerprint = fingerprint;
        axios.post(this.apiUrl,fingerprint)
            .then(response => {

              if (response.data.status==0){
                console.log(response.data.invite_code)
                var f1 = JSON.stringify(response.data.invite_code)
                if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {

                  window.webkit.messageHandlers.getInvitationCode.postMessage(f1);

                } else if (navigator.userAgent.match(/android/i)) {

                  window.AndroidNative.getInvitationCode(response.data.invite_code);

                }

              }
            })
            .catch(error => {
              console.error('提交错误:', error);
            });
      })

      // 创建提交数据的方法

    },
    

  }

}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
